import auth from "redux/types/auth";
import currentUser from "redux/types/currentUser";
import theme from "redux/types/theme";
import users from "redux/types/users";
import protfolio from "redux/types/portfolio";
import invest from "redux/types/pendingInvest";

export default {
  auth,
  currentUser,
  theme,
  users,
  protfolio,
  invest,
};