import { get, post, remove } from "utils/request";


export async function readPInvests(payload) {
  const {accessToken} = payload;
  return get("/cReport/requests", accessToken);
}

export async function createPInvests(payload) {
  const {accessToken, data} = payload;
  return post("/cReport/sendRequest", data, accessToken);
}

export async function deletePInvests(payload) {
  const {accessToken, id} = payload;
  return remove("/cReport/deleteRequest/"+id, accessToken);
}