import { all, call, put, takeEvery } from "redux-saga/effects";

import types from "redux/types";
import {
  getCurrentUserSuccess,
  getCurrentUserError,
  updateUserInfoSuccess,
  updateUserInfoError,
} from "redux/actions/currentUser";
import { getCurrentUser, addSocialProfileLink, updateUserInfo, updateUserGenres, } from "api/users";
import { getCurrentUserRequest } from "redux/actions/currentUser";
import { showSuccessToastWithIcon, showErrorToastWithIcon } from "components/ReactNotifications/ReactNotifications";

function* handleGetCurrentUser(action) {
  try {
    const response = yield call(getCurrentUser, action.payload);
    const { error } = response;
    if (error) throw error;
    yield put(getCurrentUserSuccess(response.data));
  } catch (error) {
    yield put(getCurrentUserError(error));
  }
}

function* watchGetCurrentUser() {
  yield takeEvery(types.currentUser.GET_CURRENT_USER_REQUEST, handleGetCurrentUser);
}



function* handleUpdateUserInfo(action) {
  const { id, accessToken } = action.payload;
  try {
    const response = yield call(updateUserInfo, action.payload);
    const { error } = response;
    if (error) throw error;

    yield put(updateUserInfoSuccess(response.data));
    yield put(getCurrentUserRequest({ id: id, accessToken: accessToken}));

    showSuccessToastWithIcon("Succesfully updated your info.");
  } catch (error) {
    yield put(updateUserInfoError(error));
    showErrorToastWithIcon("Error updating your info.");
  }
}
function* watchUpdateUserInfo() {
  yield takeEvery(types.currentUser.UPDATE_USER_DATA_REQUEST, handleUpdateUserInfo);
}


export default function* usersSaga() {
  yield all([watchGetCurrentUser(), watchUpdateUserInfo()]);
}
