/*eslint-disable*/
import React, {useState} from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import './Header.scss';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Menu, Layout } from 'antd';
import icon from "./favicon.ico";
import action from 'redux/actions';

import {
  EditOutlined,
  UserOutlined,
  LogoutOutlined,
  BulbOutlined,
  StarOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';
const { Sider } = Layout;
const { SubMenu } = Menu;
export default ({currentUser}) => {
  const dispatch = useDispatch();
  const themeState = useSelector(state=>state.theme);
  const [mode, setMode] = useState('horizontal');
  const [theme, setTheme] = useState(themeState?.theme ? themeState?.theme : 'light');
  const { innerWidth: width } = window;
  const [collapsed, setCollapsed] = useState(width <= 992);
  const fullname = currentUser && currentUser.name ? currentUser.name : "User";

  //console.log({currentUser, fullname});
  const getCurrentRoute = ()=>{
    const location = useLocation();
    return location?.pathname.replace(/\//g, "") ? location?.pathname.replace(/\//g, "") : '';
  }

  const changeMode = value => {
    setMode(value ? 'vertical' : 'inline');
  };

  const changeTheme = (currentTheme) => {
    let changeTheme = '';
    if(currentTheme === "light"){
      changeTheme = 'dark';
    }else{
      changeTheme = 'light';
    }
    setTheme(changeTheme);
    dispatch(action.theme.changeTheme(changeTheme));
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <div className="site-header">
        <div className={theme === 'dark'? "headers-dark " : "headers"}>
          <Link to={paths.dashboard}> <img src={icon} className="sidebar-icon"/></Link>
          <Menu
            defaultSelectedKeys={[getCurrentRoute()]}
            mode={mode}
            theme={theme}
            className={theme === 'dark'? "header-menu-dark " : "header-menu"}
          >
          {/* <Menu.Item key="theme" icon={theme === 'dark'? <BulbOutlined />: <StarOutlined /> } onClick={()=>changeTheme(theme)}>
          </Menu.Item>
           */}
            <SubMenu key="profile" icon={<UserOutlined />} title={fullname}>
              {/* <Menu.Item key="editProfile" icon={<EditOutlined />} >
                <Link to={paths.editProfile}>
                  Profil bearbeiten
                </Link>
              </Menu.Item> */}
              <Menu.Item key="changePassword" icon={<EyeInvisibleOutlined/>} >
                <Link to={paths.changePassword}>
                Passwort ändern
                </Link>
              </Menu.Item>
              <Menu.Item key="addException" icon={<LogoutOutlined />} danger>
                <Link to={paths.logout} >
                Ausloggen
                </Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </div>
      <br/>
    </>
  );
};
