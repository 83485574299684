import types from "redux/types";

export const createPInvestReq = (payload) => {
  return {
    type: types.invest.CREATE_PINVEST,
    payload,
  };
};
export const createPInvestSuccess = (payload) => {
  return {
    type: types.invest.CREATE_PINVEST_SUCCESS,
    payload,
  };
};
export const createPInvestError = (payload) => {
  return {
    type: types.invest.CREATE_PINVEST_ERROR,
    payload,
  };
};

export const readPInvesttReq = (payload) => {
  return {
    type: types.invest.READ_PINVEST,
    payload,
  };
};
export const readPInvestSuccess = (payload) => {
  return {
    type: types.invest.READ_PINVEST_SUCCESS,
    payload,
  };
};
export const readPInvestError = (payload) => {
  return {
    type: types.invest.READ_PINVEST_ERROR,
    payload,
  };
};


export const deletePInvestReq = (payload) => {
  return {
    type: types.invest.DELETE_PINVEST,
    payload,
  };
};
export const deletePInvestSuccess = (payload) => {
  return {
    type: types.invest.DELETE_PINVEST_SUCCESS,
    payload,
  };
};
export const deletePInvestError = (payload) => {
  return {
    type: types.invest.DELETE_PINVEST_ERROR,
    payload,
  };
};
