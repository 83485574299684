import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import selectors from "redux/selectors";
import actions from "redux/actions";

import Header from "components/Header/Header.js";
import SideBar from "components/Sidebar/Sidebar";

//import './PrivateRoute.scss';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn, currentUser } = rest;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && currentUser.email_verification && !currentUser.suspended? (
          <div className="privatePages">
            <Header currentUser={currentUser} location={props.location} />
            <SideBar currentUser={currentUser}/>
            <Component {...props} />
          </div>
        ) : (
          <Redirect to={{ pathname: "/logout", state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  
  isLoggedIn: selectors.auth.getIsLoggedIn(state),
  currentUser: selectors.currentUser.getCurrentUserData(state),
});

const mapDispatchToProps = (dispatch) => ({
  logoutRequest: () => dispatch(actions.auth.logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
