import * as auth from "redux/actions/auth";
import * as currentUser from "redux/actions/currentUser";
import * as theme from "redux/actions/theme";
import * as users from "redux/actions/users";
import * as invest from "redux/actions/pendingInvest";
import * as portfolio from "redux/actions/portfolio";

export default {
  auth,
  currentUser,
  theme,
  users,
  portfolio,
  invest,
};
