/*eslint-disable*/
import React, {useState} from "react";
import { Link } from "react-router-dom";
import paths from "routes/paths";
import './sidebar.scss';
import icon from "./favicon.ico"
import logo from "./logofull.png"
import { useLocation } from 'react-router-dom'

import { useSelector } from "react-redux";
import { Menu, Layout} from 'antd';
import {
  DashboardOutlined,
  PlusCircleOutlined,
  AppstoreOutlined,
  SettingOutlined,
  TeamOutlined,
  PlusCircleFilled,
  PartitionOutlined,
  UserSwitchOutlined,
  FundFilled,
  PieChartFilled,
  FundOutlined
} from '@ant-design/icons';

const { Sider } = Layout;
const { SubMenu } = Menu;
export default (props) => {
  const {currentUser} = props;
  const [mode, setMode] = useState('inline');
  const themeState = useSelector(state=>state.theme);
  const { innerWidth: width } = window;
  const [collapsed, setCollapsed] = useState(width <= 992);
  
  const getCurrentRoute = ()=>{
    const location = useLocation();
    return location?.pathname.replace(/\//g, "") ? location?.pathname.replace(/\//g, "") : '';
  }

  const changeMode = value => {
    setMode(value ? 'vertical' : 'inline');
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
    console.log({collapsed});
  };

  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState();
  const showDefaultDrawer = () => {
    setSize('default');
    setVisible(true);
  };
  const showLargeDrawer = () => {
    setSize('large');
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={`site-navigation ${!collapsed ? "site-navigation-open": ""}`}>
      <Sider 
        collapsible 
        collapsed={collapsed} 
        onCollapse={onCollapse}
        width={256}
        // height="100%"
        theme={themeState?.theme ? themeState?.theme : 'light'}
        className={`sidebar-menu-${themeState.theme} siderbar-menu-custom`}
        breakpoint="lg"
        collapsedWidth={0}
        //style={{position:'fixed !important', maxWidth:"256px", width:"256px !important", height:"100vh"}}
      >
        <div className="logo">
          <div className="sidebar-title">
            <Link to={paths.dashboard}>
              {!collapsed &&<img src={logo} className="sidebar-logo" style={{margin:10}}/>}
              
            </Link>
          </div>
        </div>
        <Menu
          defaultSelectedKeys={[getCurrentRoute()]}
          mode={mode}
          theme={themeState?.theme ? themeState?.theme : 'light'}
          multiple={false}
          inlineIndent={20}
          // style={{position:'fixed', maxWidth:"256px"}}
        >
          {currentUser.role === "CLIENT" ?
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to={paths.dashboard}>
            Dashboard
            </Link>
          </Menu.Item>
          :null}
        </Menu>
        
      </Sider>
    </div>
  );
};
