export default {

  READ_PINVEST: "/pinvest/read/request",
  READ_PINVEST_SUCCESS: "/pinvest/read/success",
  READ_PINVEST_ERROR: "/pinvest/read/error",

  CREATE_PINVEST: "/pinvest/create/request",
  CREATE_PINVEST_SUCCESS: "/pinvest/create/success",
  CREATE_PINVEST_ERROR: "/pinvest/create/error",

  DELETE_PINVEST: "/pinvest/delete/request",
  DELETE_PINVEST_SUCCESS: "/pinvest/delete/success",
  DELETE_PINVEST_ERROR: "/pinvest/delete/error",
};