import { combineReducers } from "redux";

import auth from "redux/reducers/auth";
import currentUser from "redux/reducers/currentUser";
import theme from "redux/reducers/theme";
import users from "redux/reducers/users";
import invest from "redux/reducers/pendingInvest";
import portfolio from "redux/reducers/portfolio";

const reducers = combineReducers({
  auth,
  currentUser,
  theme,
  users,
  portfolio,
  invest
});

export default reducers;
