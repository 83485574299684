import types from "redux/types";



const initialState = {
  loading: false,
  success: false,
  data: [],
  error: []
}

const initialUserState = {
  read_pinvest: {
    ...initialState,
  },
  create_pinvest: {
    ...initialState,
  },
  delete_pinvest: {
    ...initialState,
  },
};

export default (state = initialUserState, action) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {

    //create
    case types.invest.READ_PINVEST:
      return {
        ...state,
        read_pinvest: {
          ...initialState,
          loading: true,
        }
      };
    case types.invest.READ_PINVEST_SUCCESS:
      return {
        ...state,
        read_pinvest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.invest.READ_PINVEST_ERROR:
      return {
        ...state,
        read_pinvest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    //
    case types.invest.CREATE_PINVEST:
      return {
        ...state,
        create_pinvest: {
          ...initialState,
          loading: true,
        }
      };
    case types.invest.CREATE_PINVEST_SUCCESS:
      return {
        ...state,
        create_pinvest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.invest.CREATE_PINVEST_ERROR:
      return {
        ...state,
        create_pinvest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    //
    case types.invest.DELETE_PINVEST:
      return {
        ...state,
        delete_pinvest: {
          ...initialState,
          loading: true,
        }
      };
    case types.invest.DELETE_PINVEST_SUCCESS:
      return {
        ...state,
        delete_pinvest: {
          ...initialState,
          success: true,
          data: payload?.data,
        }
      };
    case types.invest.DELETE_PINVEST_ERROR:
      return {
        ...state,
        delete_pinvest: {
          ...initialState,
          success: false,
          error: payload?.data,
        }
      };
    
    default:
      return state;
  }
};