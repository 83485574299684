import React from "react";
//import Logo from './logo.png';
import "./PageLoader.scss";
export default (props) => {
  return (
    <div className="page--loader">
      <div className="loader-box">
        <div className="img-box">
          {/** <img width="77" height="76" src={Logo} alt="Fantagious co." />*/}
        </div>
        <div className="loader"/>
      </div>
    </div>
  );
};
