import * as auth from "redux/selectors/auth";
import * as currentUser from "redux/selectors/currentUser";
import * as theme from "redux/selectors/theme";
import * as users from "redux/selectors/users";
import * as portfolio from "redux/selectors/portfolio";
import * as invest from "redux/selectors/pendingInvest";

export default {
  auth,
  currentUser,
  theme, 
  users,
  portfolio,
  invest
};
