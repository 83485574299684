import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import types from "redux/types";
import { loginSuccess, loginError, logoutSuccess, logoutError, signupSuccess, signupError, emailVerifySuccess, emailVerifyError, forgotPasswordSuccess, forgotPasswordError, forgotPasswordReset, resetPasswordSuccess, resetPasswordError, resetPasswordReset } from "redux/actions/auth";
import { getCurrentUserRequest, clearCurrentUser } from "redux/actions/currentUser";

import { login, signup, verifyEmail, forgotPassword, resetPassword } from "api/auth";

function* handleLoginRequest(action) {
  const { email, password } = action.payload;

  try {
    const response = yield call(login, { email, password });

    const { error } = response;
    if (error) throw error;
    yield put(getCurrentUserRequest({id: response.userID, accessToken: response.accessToken}));
    yield put(loginSuccess(response));
    // Used by landing page
    localStorage.setItem("isLoggedIn", true);
  } catch (error) {
    yield put(loginError(error));
  }
}

function* watchLoginRequest() {
  yield takeEvery(types.auth.LOGIN_REQUEST, handleLoginRequest);
}

//Signup Saga
function* handleSignupRequest(action) {
  const { name, email, password, mobile, gender, role } = action.payload;

  try {
    const response = yield call(signup, {
      name,
      email,
      password,
      mobile,
      gender,
      role,
    });

    const { error } = response;
    if (error) throw error;

    yield put(signupSuccess(response));
  } catch (error) {
    yield put(signupError(error));
  }
}

function* watchSignupRequest() {
  yield takeEvery(types.auth.SIGNUP_REQUEST, handleSignupRequest);
}

//Logout saga
function* handleLogoutRequest(action) {
  try {
    yield put(clearCurrentUser());
    yield put(logoutSuccess(action.payload));
    localStorage.clear();
    // Used by landing page
    localStorage.setItem("isLoggedIn", false);
  } catch (error) {
    yield put(logoutError(error));
  }
}

function* watchLogoutRequest() {
  yield takeLatest(types.auth.LOGOUT_REQUEST, handleLogoutRequest);
}

//Email Verification Sage
function* handleEmailVerificationRequest(action) {
  const emailVerificationToken = action.payload;

  try {
    const response = yield call(verifyEmail, emailVerificationToken);

    const { error } = response;
    if (error) throw error;

    yield put(emailVerifySuccess(action.payload));
  } catch (error) {
    yield put(emailVerifyError(error));
  }
}

function* watchEmailVerificationRequest() {
  yield takeEvery(types.auth.EMAIL_VERIFY_REQUEST, handleEmailVerificationRequest);
}

//Frorgot Password Saga
function* forgotPasswordRequest(action) {
  const forgotPasswordParams = action.payload;

  try {
    const response = yield call(forgotPassword, forgotPasswordParams);

    const { error } = response;
    if (error) throw error;

    yield put(forgotPasswordSuccess(response));
    yield put(forgotPasswordReset());
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

function* watchForgotPasswordRequest() {
  yield takeEvery(types.auth.FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
}

//Reset Password Saga
function* resetPasswordRequest(action) {
  const resetPasswordParams = action.payload;

  try {
    const response = yield call(resetPassword, resetPasswordParams);

    const { error } = response;
    if (error) throw error;

    yield put(resetPasswordSuccess(response));
    yield put(resetPasswordReset());
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

function* watchResetPasswordRequest() {
  yield takeEvery(types.auth.RESET_PASSWORD_REQUEST, resetPasswordRequest);
}


export default function* authSaga() {
  yield all([watchLoginRequest(), watchSignupRequest(), watchLogoutRequest(), watchEmailVerificationRequest(), watchForgotPasswordRequest(), watchResetPasswordRequest()]);
}
