import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import PageLoader from 'components/PageLoader/PageLoader';

import Paths from './paths';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

/**Auth Paths */
const Login = lazy(() => import('pages/auth/Login/login'));
const Signup = lazy(() => import('pages/auth/Signup/signup'));
const Logout = lazy(() => import('pages/auth/Logout/Logout'));

/*Email */
const ConfirmEmail = lazy(() => import('pages/auth/ConfirmEmail/ConfirmEmail'));
const EmailVerification = lazy(() =>import('pages/auth/EmailVerification/EmailVerification'));
const ForgotPassword = lazy(() =>import('pages/auth/ForgotPassword/ForgotPassword'));
const ResetPasswordLinkSent = lazy(() =>import('pages/auth/ResetPasswordLinkSent/ResetPasswordLinkSent'));
const ResetPassword = lazy(() =>import('pages/auth/ResetPassword/ResetPassword'));
const ResetPasswordSuccess = lazy(() =>import('pages/auth/ResetPasswordSuccess/ResetPasswordSuccess'));


/** CMS Paths */
const Dashboard = lazy(() => import('pages/Dashboard/Home/'));
const EditProfile = lazy(() => import('pages/Profile/editProfile'));
const ChangePassword = lazy(() => import('pages/Profile/ChangePassword/changePassword'));
const Users = lazy(() => import('pages/Dashboard/Users/Users'));

const Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={<PageLoader />}>
          {/* Index Routes */}
          <PublicRoute exact path={Paths.index} component={Login} />
          <PublicRoute exact path={Paths.signup} component={Signup} />
          <PublicRoute exact path={Paths.logout} component={Logout} />

          <PublicRoute exact path={Paths.confirmEmail} component={ConfirmEmail} />
          <PublicRoute exact path={Paths.emailVerification} component={EmailVerification} />
          <PublicRoute exact path={Paths.forgotPassword} component={ForgotPassword} />
          <PublicRoute exact path={Paths.resetPasswordLinkSent} component={ResetPasswordLinkSent} />
          <PublicRoute exact path={Paths.resetPassword} component={ResetPassword} />
          <PublicRoute exact path={Paths.resetPasswordSuccess} component={ResetPasswordSuccess} />
          
          {/* Private Routes */}
          
          {/* Every User Right */}
          <PrivateRoute exact path={Paths.editProfile} component={EditProfile} />
          <PrivateRoute exact path={Paths.changePassword} component={ChangePassword} />
          
          {/* Strickly Admin */}
          <PrivateRoute exact path={Paths.dashboard} component={Dashboard} />
          <PrivateRoute exact path={Paths.users} component={Users} />
          
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
