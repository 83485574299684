import {
  all,
  call,
  put,
  takeEvery
} from "redux-saga/effects";

import types from "redux/types";
import {
  createPInvestError,
  createPInvestSuccess,

  readPInvestError,
  readPInvestSuccess,

  deletePInvestError,
  deletePInvestSuccess
} from "redux/actions/pendingInvest";

import {
  createPInvests,
  readPInvests,
  deletePInvests
} from "api/pendingInvest";

import { showSuccessToastWithIcon, showErrorToastWithIcon } from "components/ReactNotifications/ReactNotifications";


function* createPInvestReq(action) {
  try {
    const response = yield call(createPInvests, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    showSuccessToastWithIcon('Invest Request Sent!!!');
    yield put(createPInvestSuccess(response));

  } catch (error) {
    if(error?.message) showErrorToastWithIcon(error?.message?.toString());
    else if(error?.error) showErrorToastWithIcon(error?.message?.toString());
    else showErrorToastWithIcon('Error reading Invest Request.');
    yield put(createPInvestError(error));
  }
}

function* createPInvestSaga() {
  yield takeEvery(types.invest.CREATE_PINVEST, createPInvestReq);
}

function* readPInvestReq(action) {
  try {
    const response = yield call(readPInvests, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;

    yield put(readPInvestSuccess(response));

  } catch (error) {
    if(error?.message) showErrorToastWithIcon(error?.message?.toString());
    else if(error?.error) showErrorToastWithIcon(error?.message?.toString());
    else showErrorToastWithIcon('Error reading pending invest.');
    yield put(readPInvestError(error));
  }
}

function* readPInvestSaga() {
  yield takeEvery(types.invest.READ_PINVEST, readPInvestReq);
}

function* deletePInvestReq(action) {
  try {
    const response = yield call(deletePInvests, action.payload);

    const {
      error
    } = response;

    if (error) throw response;
    if(response?.data?.error) throw response?.data;
    showSuccessToastWithIcon("Invest Request Successfully Deleted.");
    yield put(deletePInvestSuccess(response));

  } catch (error) {
    if(error?.message) showErrorToastWithIcon(error?.message?.toString());
    else if(error?.error) showErrorToastWithIcon(error?.message?.toString());
    else showErrorToastWithIcon('Error Deleting Pending Invest. ');
    yield put(deletePInvestError(error));
  }
}

function* deletePInvestSaga() {
  yield takeEvery(types.invest.DELETE_PINVEST, deletePInvestReq);
}

//add all the saga
export default function* iaccountsSaga() {
  yield all([
    createPInvestSaga(),
    deletePInvestSaga(), 
    readPInvestSaga()
  ]);
}