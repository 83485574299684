import { all } from "redux-saga/effects";

import authSaga from "redux/sagas/auth";
import currentUserSaga from "redux/sagas/currentUser";
import users from "redux/sagas/users";
import invest from "redux/sagas/pendingInvest";
import portfolio from "redux/sagas/portfolio";

export default function* rootSaga() {
  yield all([
    authSaga(), 
    currentUserSaga(), 
    users(),
    portfolio(),
    invest()
  ]);
}
