import { get, post, remove } from "utils/request";


export async function getAllClients(payload) {
  const {accessToken} = payload;
  return get("/clients/all", accessToken);
}

export async function createClient(payload) {
  const {accessToken, data} = payload;
  return post("/clients/create", data, accessToken);
}

export async function updateClient(payload) {
  const {accessToken, data, id} = payload;
  return post("/clients/update/"+id, data, accessToken);
}

export async function deleteClient(payload) {
  const {accessToken, id} = payload;
  return remove("/clients/remove/"+id, accessToken);
}

//interest
export async function createInterest(payload) {
  const {accessToken, data} = payload;
  return post("/clients/interest", data, accessToken);
}

export async function updateInterest(payload) {
  const {accessToken, data, id} = payload;
  return post("/clients/interest/"+id, data, accessToken);
}

export async function deleteInterest(payload) {
  const {accessToken, id} = payload;
  return remove("/clients/interest/"+id, accessToken);
}
//invest
export async function createInvest(payload) {
  const {accessToken, data} = payload;
  return post("/clients/invest", data, accessToken);
}

export async function updateInvest(payload) {
  const {accessToken, data, id} = payload;
  return post("/clients/invest/"+id, data, accessToken);
}

export async function deleteInvest(payload) {
  const {accessToken, id} = payload;
  return remove("/clients/invest/"+id, accessToken);
}

export async function readReport(payload) {
  const {accessToken} = payload;
  return get("/cReport/summary", accessToken);
}

export async function readSummary(payload) {
  const {accessToken} = payload;
  return get("/clients/summary", accessToken);
}

export async function sendReport(payload) {
  const {accessToken, data} = payload;
  return post("/clients/sendReport", data, accessToken);
}