import { get, post } from "utils/request";

export async function login(loginData) {
  return post("/cauth/login", loginData);
}

export async function signup(signupData) {
  return post("/cauth/register", signupData);
}

export async function verifyEmail(verificationToken) {
  return get(`/cauth/email-verification/${verificationToken}`);
}

export async function forgotPassword(forgotPasswordParams) {
  return post("/cauth/reset-password-link", forgotPasswordParams);
}

export async function resetPassword(resetPasswordParams) {
  return post("/cauth/reset-password", resetPasswordParams);
}