import { get, post, remove } from "utils/request";

export async function getAllUsers(accessToken) {
  return get("/users", accessToken);
}

export async function getUserByID(data) {
  const { userID, accessToken } = data;
  const url = "/cusers/" + userID;
  return get(url, accessToken);
}

export async function getCurrentUser(data) {
  const { id, accessToken } = data;
  return get(`/cusers/${id}`, accessToken);
}

export async function updateUserInfo(payload) {
  const { id, data, accessToken } = payload;
  return post(`/cusers/${id}`, data, accessToken);
}

export async function deleteUser(data) {
  const {id, accessToken} = data;
  return remove(`/cusers/${id}`, accessToken);
}

